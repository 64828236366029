import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { LayoutBlankModule } from './layout_blank/layout_blank.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { AppService } from './core/services/base.service';
import { LoggingService } from './core/services/log.service';
import { AuthService } from './core/services/auth.service';
import { SharedService } from './shared/shared.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import {LocationStrategy, PathLocationStrategy, DatePipe} from "@angular/common";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [ModalModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        LayoutBlankModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })], providers: [
        AppService,
        LoggingService,
        AuthService,
        SharedService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
