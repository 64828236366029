import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { menu } from './menu';
import { routes } from './routes';
import { TecModule } from './pages/tec/tec.module';
import { Error404Module } from './pages/error404/error404.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { useHash: true }),
        PagesModule,
        Error404Module,
        ConfigurationModule,
        TecModule
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService) {
        menuService.addMenu(menu);
    }
}
