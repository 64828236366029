<div class="container-fluid">
  <div class="row align-items-center mt-2">
    <div class="col-12 col-md">
      <p style="margin-bottom: 0; line-height: 30px;">
        <span>&copy; {{settings.getAppSetting('year')}} Tutti i diritti riservati. Powered by</span>
        <img class="logo ml-2" style="padding-top: 6px;" src="assets/img/service-pay-logo.png" alt="Servicepay" />
      </p>
    </div>

    <div class="col-auto d-flex justify-content-center">
      <div class="footer_dropdown_wrapper">

        <div class="select-lang dropdown" dropdown [dropup]="true">
          <a href="#" class="dropdown-toggle current-lang" dropdownToggle role="button" aria-expanded="false">
            <img class="img-responsive" src="assets/img/country/{{selectedLanguage || 'IT'}}.png" style="max-width: 30px;" />
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu arrow-center user-menu lang-list" *dropdownMenu>
            <!-- <li *ngFor="let lang of avaibleLangs" class="lang-item"><a class="nav-link">{{ lang.text }}</a></li> -->
            <li *ngFor="let lang of avaibleLangs" class="lang-item" (click)="setLang(lang.code)">
              <a class="nav-link">
                <img class="img-responsive" src="assets/img/country/{{lang.code}}.png" style="max-width: 30px;" />
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div class="col">
      <p style="margin-bottom: 0; line-height: 30px; text-align: right;">v. {{settings.getAppSetting('version')}}</p>
    </div>

  </div>
</div>
