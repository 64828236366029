import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { PaymentCodeComponent } from './payment-code/payment-code.component';
import { AddMethodComponent } from './payments/stripe/add-method/add-method.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PaymentCodeComponent,
        AddMethodComponent
    ],
    exports: [
        RouterModule
    ]
})
export class PagesModule { }
