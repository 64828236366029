<div class="wrapper" style="min-height: 100%;">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar class="aside-container only-mobile"></app-sidebar>
    <!-- Main section-->
    <!-- <section class="section-container"> -->
        <section class="pb-60 section-wrapper" [ngClass]="{'fixPadding':profile == 'RESELLER' || profile == 'RESPONSIBLE' ||  profile == 'AGENT'}">
        <!-- Page content-->
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- Page footer-->
    <app-footer class="footer-container"></app-footer>
</div>
