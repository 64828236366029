<div class="wrapper page container">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'tec.title' | translate}}</h3>
      <a href="#">
        <img class="float-right block-center rounded " style="height:40px;" src="assets/img/service-pay-logo.png" alt="Image" />
      </a>
    </div>
  </div>
  <div class="row card">
    <div class="col-12 app-search">
      <div class="text-center p-2"> 
        <h2 class="text-center p-3">{{'tec.title' | translate}}</h2>
        <p class="h4 text-start " style="line-height: 1.5em;" [innerHTML]="'tec.text' | translate">
        </p>
      </div>
    </div>
  </div>
</div>