import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeElementComponent } from '../../../../elements/stripe-element/stripe-element.component';
import { AppService } from '../../../../../core/services/base.service';
import { PaymentService } from '../../../../../services/payment.service';
import { LoggingService } from '../../../../../core/services/log.service';
import { Router , ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppRetailsService } from '../../../../../services/app-retails.service';
import { PaymentMethodType, CustomerPaymentMethodType } from '../../../../../core/classes/interfaces.interface';
import moment from 'moment';
import swal from 'sweetalert2';

moment.locale('it');

declare var $: any;

@Component({
  selector: 'app-add-method',
  templateUrl: './add-method.component.html',
  styleUrls: ['./add-method.component.scss']
})
export class AddMethodComponent implements OnInit {
  
  @ViewChild(StripeElementComponent) private _stripe: StripeElementComponent;
  public activated: boolean;
  public responseArrived: boolean;
  public errorMessage: any = '';
  private paymentMethods: string[];
  public customerData: any;
  // public isTec = 0;
  // public isGDPR = 0;

  stripeValid: number = 0;
  elements: any;
  cardElement: any;
  sepaElement: any;
  paymentElement: any;
  stripe: any;
  stripeForm: any;
  idCustomer: any;
  retailInfo: any;
  defaultAvailablePaymentMethods = [PaymentMethodType.CARD, PaymentMethodType.SEPA_DEBIT, PaymentMethodType.PAYPAL];
  pathAvailablePaymentMethods = [...this.defaultAvailablePaymentMethods];
  availablePaymentMethods = [...this.pathAvailablePaymentMethods];
  addPaymentMethodSuccess: boolean = false;
  saving = false;
  returnUrl: string = '';

  constructor(
    private paymentService: PaymentService, 
    private appService: AppService, 
    private logger: LoggingService, 
    private router: Router , 
    private route: ActivatedRoute, 
    private translate: TranslateService,
    private retailsService: AppRetailsService
  ) { }

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.card != null && data.card) {
        this.pathAvailablePaymentMethods = [PaymentMethodType.CARD];
        this.availablePaymentMethods = [...this.pathAvailablePaymentMethods];
      }
    });
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
      this.paymentMethods = params['payment_methods']?.toLowerCase().split(',');
      if (this.paymentMethods) {
        sessionStorage.setItem('paymentMethods', params['payment_methods']);
        this.router.navigate([], { relativeTo: this.route });
      } else if (sessionStorage.getItem('paymentMethods')) {
        this.paymentMethods = sessionStorage.getItem('paymentMethods')?.toLowerCase().split(',');
      }
      if (this.paymentMethods && this.paymentMethods.length) {
        const availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          this.paymentMethods.some(paymentMethod => paymentMethod == availablePaymentMethod));
        if (availablePaymentMethods.length) {
          this.availablePaymentMethods = availablePaymentMethods;
        }
      }
    });
    this.route.params.subscribe(params => {
      this.idCustomer = params['idCustomer'];
      // this.logger.log('this.idCustomer' , this.idCustomer , 200)
      this.loadCustomerData();
      this.responseArrived = true;
    });
  }

  async loadCustomerData() {
    let resp = await this.appService.getAllFromData('/customer/data' , {idCustomer: this.idCustomer} ).toPromise();
    // this.logger.log( 'loadData - resp' , resp , 200)
    if (!resp || resp.success == false) {
      this.activated = false;
    } else {
      this.customerData = resp;
      this.activated = true;

      if (this.customerData.idExternalApp) {
        this.getRetail(this.customerData.idExternalApp);
      }
    }
  }

  onPaymentMethodError() {
    setTimeout(() => {
      if (this.paymentMethods && this.paymentMethods.length) {
        this.availablePaymentMethods = [...this.pathAvailablePaymentMethods];
      }
    }, 0);
  }

  getStripeValidation( event ) {
    if(event == 'valid') {
      this.stripeValid = 1;
    } else {
      this.stripeValid = 0;
    }
  }

  async getRetail( id: number ) {
    let response = await this.retailsService.getVendorById(id).toPromise();
    // this.logger.log('vendor' , response , 200);
    if (!response || response.success == false) {
      this.retailInfo = null;
    } else {
      this.retailInfo = response;
      var link = $("<link />", {
        rel: "stylesheet",
        type: "text/css",
        href: "assets/css/"+response.color+".css"
      });
      $('#color').html(link);
    }
  }

  // changeTeC() {
  //   if(this.isTec == 0) this.isTec = 1;
  //   else this.isTec = 0;
  // }

  // changeGDPR() {
  //   if(this.isGDPR == 0) this.isGDPR = 1;
  //   else this.isGDPR = 0;
  // }

  private isPaymentMethodGoingToExpire(paymentMethod) {
    if (!paymentMethod || !paymentMethod.expMonth || !paymentMethod.expYear) {
      return false;
    }
    const lastExpirationDate = moment().toDate();
    return (paymentMethod.expYear < lastExpirationDate.getFullYear() ||
      (paymentMethod.expYear == lastExpirationDate.getFullYear() && 
        paymentMethod.expMonth <= lastExpirationDate.getMonth() + 1));
  }

  saveMethodOld() {
    swal.fire({
      title: '',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => swal.showLoading()
    });
    this.getStripeElRef();
    var component = this;

    this.saving = true;
    switch (this.stripeForm.value.paymentMethodType) {
      case PaymentMethodType.CARD:
        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: {
            name: this.stripeForm.value.name,
            email: this.stripeForm.value.email
          },
        })
        .then(async function(result) {
          // Handle result.error or result.paymentMethod
          if( result.paymentMethod ) {
            if ( result.paymentMethod.card.funding === 'prepaid' && component.customerData.acceptPrepaid == false ) {
              swal.fire({
                title: component.translate.instant('msg.error'),
                text: component.translate.instant('sale.card_not_supported'),
                icon: "error",
                showCancelButton: component.returnUrl && component.returnUrl != '',
                cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
              }).then( res => {
                if( res.dismiss ) {
                  component.goToReturnUrl();
                }
              })
              component.saving = false;
            } else if ( result.paymentMethod.card.brand === 'amex' && component.customerData.amex == false ) {
              swal.fire({
                title: component.translate.instant('msg.error'),
                text: component.translate.instant('sale.card_not_supported'),
                icon: "error",
                showCancelButton: component.returnUrl && component.returnUrl != '',
                cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
              }).then( res => {
                if( res.dismiss ) {
                  component.goToReturnUrl();
                }
              })
              component.saving = false;
            } else {
              //create payment method and confirm
              // component.logger.log( 'result.paymentMethod' , result.paymentMethod , 200 )
              const requestCheckCard = {
                idCustomer: component.idCustomer,
                pmType: PaymentMethodType.CARD,
                type: result.paymentMethod.card.brand,
                country: result.paymentMethod.card.country,
                last4: result.paymentMethod.card.last4,
                expMonth: result.paymentMethod.card.exp_month,
                expYear: result.paymentMethod.card.exp_year
              }
              let respExist = await component.appService.getAllFromData('/customer/pm/exist' , requestCheckCard ).toPromise()
              // component.logger.log('check exist pm - resp' , respExist , 200 )
              if( respExist ) {
                swal.fire({
                  title: component.translate.instant('msg.error'),
                  text: component.translate.instant('payment_method.msg_payment_method_exist'),
                  icon: "error"
                })
                component.saving = false;
              } else {
                const requestObj = {idCustomer : component.idCustomer}
                let resp = await component.paymentService.newSetupIntent( requestObj ).toPromise();
                if ( resp ) {
                  // component.logger.log( 'new setup intent' , resp , 200)
                  component.stripe.confirmCardSetup( resp.data.clientSecret , {payment_method: result.paymentMethod.id} ).then(function( result ) {
                    if( result.setupIntent ) {
                      swal.close()
                      component.addPaymentMethodSuccess = true;
                      component.saving = false;
                      if( component.returnUrl && component.returnUrl != '') {
                        setTimeout(() => {
                          window.location.href = component.returnUrl;
                        }, 5000); 
                      }
                    } else {
                      swal.fire({
                        title: component.translate.instant('msg.error'),
                        text: result.error.message,
                        icon: "error",
                        showCancelButton: component.returnUrl && component.returnUrl != '',
                        cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
                      }).then( res => {
                        if( res.dismiss ) {
                          component.goToReturnUrl();
                        }
                      })
                      component.saving = false;
                    }
                  })
                } else {
                  swal.fire({
                    title: component.translate.instant('msg.error'),
                    text: component.translate.instant('payment_method.generic_setup_error'),
                    icon: "error",
                    showCancelButton: component.returnUrl && component.returnUrl != '',
                    cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
                  }).then( res => {
                    if( res.dismiss ) {
                      component.goToReturnUrl();
                    }
                  })
                  component.saving = false;
                }
              }
            }
          } else {
            swal.fire({
              title: component.translate.instant('msg.error'),
              text: result.error.message,
              icon: "error",
              showCancelButton: component.returnUrl && component.returnUrl != '',
              cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
            }).then( res => {
              if( res.dismiss ) {
                component.goToReturnUrl();
              }
            })
            component.saving = false;
          }
        });
        break;
      case PaymentMethodType.SEPA_DEBIT:
        if (this.customerData.sepa == false) {
          swal.fire({
            title: this.translate.instant('msg.error'),
            text: this.translate.instant('sale.sepa_not_supported'),
            icon: "error"
          })
          component.saving = false;
        } else {
          this.stripe.createPaymentMethod({
            type: 'sepa_debit',
            sepa_debit: this.sepaElement,
            billing_details: {
              name: this.stripeForm.value.name,
              email: this.stripeForm.value.email
            },
          })
          .then(async function(result) {
            // Handle result.error or result.paymentMethod
            if( result.paymentMethod ) {
              //create payment method and confirm
              // component.logger.log( 'result.paymentMethod' , result.paymentMethod , 200 )
              const requestCheckSepa = {
                idCustomer: component.idCustomer,
                pmType: CustomerPaymentMethodType.SEPA,
                bankCode: result.paymentMethod.sepa_debit.bank_code,
                country: result.paymentMethod.sepa_debit.country,
                last4: result.paymentMethod.sepa_debit.last4
              }
              let respExist = await component.appService.getAllFromData('/customer/pm/exist' , requestCheckSepa ).toPromise()
              // component.logger.log('check exist pm - resp' , respExist , 200 )
              if( respExist ) {
                swal.fire({
                  title: component.translate.instant('msg.error'),
                  text: component.translate.instant('payment_method.msg_payment_method_exist'),
                  icon: "error",
                  showCancelButton: component.returnUrl && component.returnUrl != '',
                  cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
                }).then( res => {
                  if( res.dismiss ) {
                    component.goToReturnUrl();
                  }
                })
                component.saving = false;
              } else {
                const requestObj = {idCustomer : component.idCustomer}
                let resp = await component.paymentService.newSetupIntent( requestObj ).toPromise();
                if ( resp ) {
                  // component.logger.log( 'new setup intent' , resp , 200)
                  component.stripe.confirmSepaDebitSetup( resp.data.clientSecret , {payment_method: result.paymentMethod.id} ).then(function( result ) {
                    if( result.setupIntent ) {
                      swal.close()
                      component.addPaymentMethodSuccess = true;
                      component.saving = false;
                      if( component.returnUrl && component.returnUrl != '') {
                        setTimeout(() => {
                          window.location.href = component.returnUrl;
                        }, 5000); 
                      }
                    } else {
                      swal.fire({
                        title: component.translate.instant('msg.error'),
                        text: result.error.message,
                        icon: "error",
                        showCancelButton: component.returnUrl && component.returnUrl != '',
                        cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
                      }).then( res => {
                        if( res.dismiss ) {
                          component.goToReturnUrl();
                        }
                      })
                      component.saving = false;
                    }
                  })
                } else {
                  swal.fire({
                    title: component.translate.instant('msg.error'),
                    text: component.translate.instant('payment_method.generic_setup_error'),
                    icon: "error",
                    showCancelButton: component.returnUrl && component.returnUrl != '',
                    cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
                  }).then( res => {
                    if( res.dismiss ) {
                      component.goToReturnUrl();
                    }
                  })
                  component.saving = false;
                }
              }
            } else {
              swal.fire({
                title: component.translate.instant('msg.error'),
                text: result.error.message,
                icon: "error",
                showCancelButton: component.returnUrl && component.returnUrl != '',
                cancelButtonText: component.translate.instant('payment_method.btn_goto_returnurl')
              }).then( res => {
                if( res.dismiss ) {
                  component.goToReturnUrl();
                }
              })
              component.saving = false;
            }
          });
        }
        break;
      default:
        component.saving = false;
        break;
    }
  }

  async saveMethod() {
    this.getStripeElRef();

    swal.fire({
      title: '',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => swal.showLoading()
    });

    this.saving = true;

    const { error: submitError } = await this.elements.submit();
    if (submitError) {
      swal.fire({
        title: this.translate.instant('msg.error'),
        text: submitError.message,
        icon: "error"
      });
      this.saving = false;
      return;
    }

    const { error: paymentMethodError, paymentMethod } = await this.stripe.createPaymentMethod({
      elements: this.elements,
      params: {
        billing_details: {
          name: this.stripeForm.controls['name'].value,
          email: this.stripeForm.controls['email'].value,
          phone: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: 'IT',
            postal_code: ''
          }
        }
      }
    });
    if (paymentMethodError) {
      const responseErrorDialog = await swal.fire({
        title: this.translate.instant('msg.error'),
        text: paymentMethodError.message,
        icon: "error",
        showCancelButton: this.returnUrl && this.returnUrl != '',
        cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
      });
      if (responseErrorDialog.dismiss) {
        this.goToReturnUrl();
      }
      this.saving = false;
      return;
    }
    let newPaymentMethod = null;
    let requestCheckPaymentMethod = null;
    switch (paymentMethod.type) {
      case PaymentMethodType.CARD:
        if (paymentMethod.card) {
          if (paymentMethod.card.funding === 'prepaid' && this.customerData.acceptPrepaid == false) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.card_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          } else if (paymentMethod.card.brand === 'amex' && this.customerData.amex == false) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.card_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          }
          newPaymentMethod = {
            idCustomerPM: paymentMethod.id,
            type: paymentMethod.card.brand,
            country: paymentMethod.card.country,
            last4: paymentMethod.card.last4,
            expMonth: parseInt(paymentMethod.card.exp_month),
            expYear: parseInt(paymentMethod.card.exp_year)
          }
          requestCheckPaymentMethod = {
            idCustomer: this.idCustomer,
            pmType: CustomerPaymentMethodType.CARD,
            type: paymentMethod.card.brand,
            country: paymentMethod.card.country,
            last4: paymentMethod.card.last4,
            expMonth: paymentMethod.card.exp_month,
            expYear: paymentMethod.card.exp_year
          }
        }
        break;
      case PaymentMethodType.SEPA_DEBIT:
        if (paymentMethod.sepa_debit) {
          if (!this.customerData.sepa) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.sepa_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          }
          newPaymentMethod = {
            idCustomerPM: paymentMethod.id,
            bankCode: paymentMethod.sepa_debit.bank_code,
            country: paymentMethod.sepa_debit.country,
            last4: paymentMethod.sepa_debit.last4
          }
          requestCheckPaymentMethod = {
            idCustomer: this.idCustomer,
            pmType: CustomerPaymentMethodType.SEPA,
            bankCode: paymentMethod.sepa_debit.bank_code,
            country: paymentMethod.sepa_debit.country,
            last4: paymentMethod.sepa_debit.last4
          }
        }
        break;
      case PaymentMethodType.PAYPAL:
        if (paymentMethod.paypal) {
          if (!this.customerData.paypal) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.paypal_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          }
          swal.fire({
            title: this.translate.instant('msg.error'),
            text: this.translate.instant('sale.paypal_not_yet_supported'),
            icon: "error"
          });
          this.saving = false;
          return;
        }
        break;
      case PaymentMethodType.KLARNA:
        if (paymentMethod.klarna) {
          if (!this.customerData.klarna) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.klarna_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          }
          swal.fire({
            title: this.translate.instant('msg.error'),
            text: this.translate.instant('sale.klarna_not_yet_supported'),
            icon: "error"
          });
          this.saving = false;
          return;
        }
        break;
      case PaymentMethodType.SATISPAY:
        if (paymentMethod.satispay) {
          if (!this.customerData.satispay) {
            const responseErrorDialog = await swal.fire({
              title: this.translate.instant('msg.error'),
              text: this.translate.instant('sale.satispay_not_supported'),
              icon: "error",
              showCancelButton: this.returnUrl && this.returnUrl != '',
              cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
            });
            if (responseErrorDialog.dismiss) {
              this.goToReturnUrl();
            }
            this.saving = false;
            return;
          }
          swal.fire({
            title: this.translate.instant('msg.error'),
            text: this.translate.instant('sale.satispay_not_yet_supported'),
            icon: "error"
          });
          this.saving = false;
          return;
        }
        break;
      default:
        const responseErrorDialog = await swal.fire({
          title: this.translate.instant('msg.error'),
          text: this.translate.instant('sale.payment_method_not_supported'),
          icon: "error",
          showCancelButton: this.returnUrl && this.returnUrl != '',
          cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
        });
        if (responseErrorDialog.dismiss) {
          this.goToReturnUrl();
        }
        this.saving = false;
        return;
    }

    if (this.isPaymentMethodGoingToExpire(newPaymentMethod)) {
      const responseConfirmDialog = await swal.fire({
        title: this.translate.instant('msg.warning'),
        text: this.translate.instant('payment_method.msg_payment_method_going_to_expire'),
        confirmButtonText: this.translate.instant('payment_method.msg_payment_method_going_to_expire_confirm'),
        showCancelButton : true,
        cancelButtonText: this.translate.instant('general.cancel'),
        icon: "warning"
      });
      if (!responseConfirmDialog.value) {
        this.saving = false;
        return;
      }
    
      swal.fire({
        title: '',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => swal.showLoading()
      });
    }

    const responseCheckPaymentMethod = await this.appService.getAllFromData('/customer/pm/exist',
      requestCheckPaymentMethod).toPromise();
    if (responseCheckPaymentMethod) {
      const responseErrorDialog = await swal.fire({
        title: this.translate.instant('msg.error'),
        text: this.translate.instant('payment_method.msg_payment_method_exist'),
        icon: "error",
        showCancelButton: this.returnUrl && this.returnUrl != '',
        cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
      });
      if (responseErrorDialog.dismiss) {
        this.goToReturnUrl();
      }
      this.saving = false;
      return;
    }

    const { success: setupIntentSuccess, data: setupIntent } = await this.paymentService.newSetupIntent(
      { idCustomer: this.idCustomer }).toPromise();
    if (setupIntentSuccess == false || !setupIntent) {
      const responseErrorDialog = await swal.fire({
        title: this.translate.instant('msg.error'),
        text: this.translate.instant('payment_method.generic_setup_error'),
        icon: "error",
        showCancelButton: this.returnUrl && this.returnUrl != '',
        cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
      });
      if (responseErrorDialog.dismiss) {
        this.goToReturnUrl();
      }
      this.saving = false;
      return;
    }

    const { error: setupIntentConfirmError } = await this.stripe.confirmSetup({
      clientSecret: setupIntent.clientSecret,
      confirmParams: {
        payment_method: paymentMethod.id
      },
      redirect: 'if_required'
    });
    if (setupIntentConfirmError) {
      const responseErrorDialog = await swal.fire({
        title: this.translate.instant('msg.error'),
        text: setupIntentConfirmError.message,
        icon: "error",
        showCancelButton: this.returnUrl && this.returnUrl != '',
        cancelButtonText: this.translate.instant('payment_method.btn_goto_returnurl')
      });
      if (responseErrorDialog.dismiss) {
        this.goToReturnUrl();
      }
      this.saving = false;
      return;
    }

    swal.close()
    this.addPaymentMethodSuccess = true;
    this.saving = false;
    if (this.returnUrl && this.returnUrl != '') {
      setTimeout(() => {
        window.location.href = this.returnUrl;
      }, 5000); 
    }
  }

  getStripeElRef() {
    this.elements = this._stripe.getStripeElement().elements;
    //this.cardElement = this._stripe.getStripeElementOld().cardElement;
    //this.sepaElement = this._stripe.getStripeElementOld().sepaElement;
    this.paymentElement = this._stripe.getStripeElement().paymentElement;
    this.stripe = this._stripe.getStripeElement().stripe;
    this.stripeForm = this._stripe.getStripeElement().stripeForm;
  }

  closeTab() {
    window.close();
  }

  goToReturnUrl() {
    window.location.href = this.returnUrl;
  }

}
