import { NgModule } from '@angular/core';
import { LayoutBlankComponent } from './layout_blank.component';
import { SharedModule } from '../shared/shared.module';
import { LangFooterComponent } from './lang-footer/lang-footer.component';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@NgModule({
    imports: [
      SharedModule
    ],
    providers: [
      BsDropdownDirective
    ],
    declarations: [
      LayoutBlankComponent,
      LangFooterComponent,
    ],
    exports: [
      LayoutBlankComponent
    ]
})
export class LayoutBlankModule { }
