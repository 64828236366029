import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatorService } from '../../core/translator/translator.service';
import { SettingsService } from '../../core/settings/settings.service';
declare var $: any;

@Component({
  selector: 'app-lang-footer',
  templateUrl: './lang-footer.component.html',
  styleUrls: ['./lang-footer.component.scss']
})
export class LangFooterComponent implements OnInit {

  avaibleLangs =  [];
  selectedLanguage = "";
  appEnvironemnt:any;

  constructor(public router: Router,
    public translator: TranslatorService,
    public settings: SettingsService) { 
      var defLang = localStorage.getItem( 'langPreference' );
      if( defLang == null ) {
        // this.selectedLanguage = this.getLangs()[0].code;
        this.selectedLanguage = this.translator.getDefaultLanguage();
      } else {
        this.selectedLanguage = defLang;
      }
    }

  ngOnInit() {
    this.avaibleLangs = this.getLangs();
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
    this.selectedLanguage = value;
  }

}
