import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration/configuration.component';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


const routes: Routes = [
  { path: '', component: ConfigurationComponent },
 
];

@NgModule({
  imports: [
		TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ConfigurationComponent],
  // exports
})

export class ConfigurationModule { }
