<div id="color"></div>
<div class="wrapper">
  <div class="block-center login-form">

    <!-- START card-->
    <div class="card card-flat text-center">
      <div class="card-header text-center bg-secondary ">
        <a href="#">
          <ng-container *ngIf="!retailInfo">
            <img class="logo block-center" src="assets/img/service-pay-logo.png" alt="ServicePay"/>
          </ng-container>
          <ng-container *ngIf="retailInfo">
            <img class="logo block-center" src="assets/img/gateway/appext_{{retailInfo.id}}/logo.png" alt="{{retailInfo.name}}" />
          </ng-container>
        </a>
      </div>
    </div>
    <div *ngIf="(errorMessage || !activated) && responseArrived" class="text-center width-450 py-3">
      <div class="icon-row">
        <em class="icon-close text-danger mr-2 fa-2x fa-lg"></em>
      </div>
      <p class="text-center py-2 text-danger secondary-text-color" *ngIf="(errorMessage || !activated) && responseArrived">{{'user.notfound' | translate}}</p>
    </div>
    <!-- END card-->
    <div *ngIf="(activated) && responseArrived && !addPaymentMethodSuccess" class="row justify-content-center py-3">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-light">
          <div class="card-header text-center">
            <h4>{{'payment_method.new_payment_method' | translate}}</h4>
          </div>
          <div class="card-body">
            
            <app-stripe-element [mode]="'manage'" [paymentData]="customerData" [availablePaymentMethods]="availablePaymentMethods"
              (onPaymentMethodError)="onPaymentMethodError()" (onValidationChange)="getStripeValidation($event)"></app-stripe-element>
            
            <hr>
            
            <div class="row">
            <!--
              <div class='form-group col-12 d-inline_block'>
                <h4>{{'sale.info_tec' | translate}}</h4>
                <div class='checkbox c-checkbox mt0 d-flex'>
                  <label for="tec">
                    <input type="checkbox" name="tec" id="tec" tec #tec (change)="changeTeC()"/>
                    <span class="fa fa-check"></span>
                  </label>
                  <div class="d-inline_block" [innerHTML]="'sale.tec' | translate"></div>
                </div>
              </div>
              
              <div class='form-group col-12 d-inline_block'>
                <h4>{{'sale.info_gdpr' | translate}}</h4>
                <div class='checkbox c-checkbox mt0 d-flex'>
                  <label for="gdpr">
                    <input type="checkbox" name="gdpr" id="gdpr" gdpr #gdpr (change)="changeGDPR()"/>
                    <span class="fa fa-check"></span>
                  </label>
                  <div class="d-inline_block" [innerHTML]="'sale.gdpr' | translate"></div>
                </div>
              </div>
            -->

              <div class="form-group col d-inline_block">
              <!--
                <button class="btn btn-primary btn-block" type="submit" [disabled]="isTec==0 || isGDPR==0 || stripeValid == 0 || saving" (click)="saveMethod()">
                  <span >{{'payment_method.btn_add_payment_method' | translate}}</span>
                </button>
              -->
                <button class="btn btn-primary btn-block" type="submit" [disabled]="stripeValid == 0 || saving" (click)="saveMethod()">
                  <span >{{'payment_method.btn_add_payment_method' | translate}}</span>
                </button>
              </div>
              <ng-container *ngIf="returnUrl && returnUrl != ''">
                <div class="form-group col d-inline_block">
                  <button class="btn btn-outline-primary btn-block" type="button" (click)="goToReturnUrl()">
                    <span>{{'payment_method.btn_goto_returnurl' | translate}}</span>
                  </button>
                </div>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(activated) && responseArrived && addPaymentMethodSuccess" class="row justify-content-center py-3">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-light">
          <div class="card-header text-center">
            <h4>{{'payment_method.summary_addmethod_title' | translate}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p >
                  {{'payment_method.summary_addmethod_text_1' | translate}} <br>
                  <span *ngIf="!returnUrl || returnUrl == ''">{{'payment_method.summary_addmethod_text_2' | translate}}</span>
                  <span *ngIf="returnUrl && returnUrl != ''">{{'payment_method.summary_addmethod_text_redirect' | translate}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

