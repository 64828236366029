import { Routes } from '@angular/router'
import { LayoutComponent } from '../layout/layout.component';
import { LayoutBlankComponent } from '../layout_blank/layout_blank.component';
//import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { PaymentCodeComponent } from './pages/payment-code/payment-code.component';
import { TecComponent } from './pages/tec/tec.component';
import { Error404Component } from './pages/error404/error404.component';
import { AddMethodComponent } from './pages/payments/stripe/add-method/add-method.component';

export const routes: Routes = [
  
  {
    path: '',
    component: LayoutBlankComponent,
    children: [
      { path: 'payment/:token', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { payment: true } },
      { path: 'payment/:token/card/:idCustomerPM/:paymentCode', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { payment: true, card: true } },
      { path: 'payment/instalment/:token/card/:idCustomerPM/:paymentCode', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { instalment: true, card: true } },
      { path: 'payment/:token/pm/:idCustomerPM/:paymentCode', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { payment: true } },
      { path: 'payment/instalment/:token/pm/:idCustomerPM/:paymentCode', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { instalment: true } },

      { path: 'payment/:token/confirm/:paymentMethod', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule), data: { payment: true } },

      { path: 'card/new/:idCustomer', component: AddMethodComponent, data: { card: true } },
      { path: 'pm/new/:idCustomer', component: AddMethodComponent },

      { path: ':codeAppExt/make-payment/cf', loadChildren: () => import('./pages/make-payments/make-payments.module').then(m => m.MakePaymentsModule), data: { customField: true } },
      { path: ':codeAppExt/make-payment/sale', loadChildren: () => import('./pages/make-payments/make-payments.module').then(m => m.MakePaymentsModule), data: { sale: true } },

      { path: ':codeAppExt/make-payment/cf/confirm/:paymentMethod', loadChildren: () => import('./pages/make-payments/make-payments.module').then(m => m.MakePaymentsModule), data: { customField: true } },
      { path: ':codeAppExt/make-payment/sale/confirm/:paymentMethod', loadChildren: () => import('./pages/make-payments/make-payments.module').then(m => m.MakePaymentsModule), data: { sale: true } },

      { path: 'sale/change/:token', loadChildren: () => import('./pages/change-sale/change-sale.module').then(m => m.ChangeSaleModule) },
      
      { path: 'payment',  component: PaymentCodeComponent },
      { path: 'gateway/first-instalment',  component: PaymentCodeComponent },
      { path: 'gateway/first-instalment/:token', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule) },
      { path: 'gateway/resolve-payment/:token', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule) },
      
      { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
      { path: 'merchant/tos/:token', loadChildren: () => import('./pages/register-tos/register-tos.module').then(m => m.RegisterTosModule) },
      { path: 'recover', loadChildren: () => import('./pages/recover/recover.module').then(m => m.RecoverModule) },
      //{ path: 'activate/:id', loadChildren: './pages/activate/activate.module#ActivateModule' },
      //{ path: 'activate-sale/:token', loadChildren: './pages/activate-sale/activate-sale.module#ActivateSaleModule' },
      
      { path: 'lock', loadChildren: () => import('./pages/lock/lock.module').then(m => m.LockModule) },
      { path: 'tec', component: TecComponent },
      { path: '500', loadChildren: () => import('./pages/error500/error500.module').then(m => m.Error500Module) },
    ]
  },
  { path: '404', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },

  // Not found
  { path: '**', redirectTo: '404' }

];
